import React from "react"
import { Link } from "gatsby"
import MarkdownView from "react-showdown"
import Layout from "../../components/Layout"
import SEO from "../../components/seo"
import "./style.scss"
import { Row, Col } from "reactstrap"
import Slider from "../../components/Slider"

export default function CategoryPage(props) {
  let imageArray = [
    "https://via.placeholder.com/300.png/09f/fff",
    "https://via.placeholder.com/300.png/09f/fff",
  ]

  if (props.pageContext.Image) {
    imageArray = props.pageContext.Image.map(img => {
      img.altText = img.alternativeText
      img.src = img.url
      return img
    })
  }

  return (
    <Layout>
      <SEO
        description={props.pageContext.Description}
        title={`${props.pageContext.Category} - Choice Automotive Equipment`}
      />
      <div className="CategoryPage p-4 bg-light">
        {props.pageContext.categories ? <strong>Go back to:</strong>: ""}
        {props.pageContext.categories &&
          props.pageContext.categories.map((item, index) => (
            <>
              {" "}
              {index !== 0 ? ", " : ""}
              <Link to={`/categories/${item.Slug}`}>{item.Category}</Link>
            </>
          ))}
        <h3 className="text-center">{props.pageContext.Category}</h3>
        <Row>
          <Col md={6}>
            <Slider items={imageArray} className="mt-auto mb-auto" />
          </Col>
          <Col md={6}>
            <MarkdownView markdown={props.pageContext.Body} />
          </Col>
        </Row>

        {props.pageContext.brand_pages.length ? (
          <h4 className="text-center mt-2">
            Brands of {props.pageContext.Category.toLowerCase()} we offer:
          </h4>
        ) : (
          ""
        )}
        <Row className="justify-content-center">
          {props.pageContext.brand_pages.map(item => (
            <Col md={2} sm={4}>
              <Link to={`/brands/${item.Slug}`}>{item.Title}</Link>
            </Col>
          ))}
          {props.pageContext.brand_pages.length ? (
            <Col md={2} sm={4}>
              ...and many more!
            </Col>
          ) : (
            ""
          )}
        </Row>
      </div>
    </Layout>
  )
}
